import { Routes, Route } from "react-router-dom";
import WEB_PAGES from "./pages";
import { PATH } from "./utils/path";
import PublicRoute from "./authorization/PublicRoute.jsx";
import ProtectedRoute from "./authorization/ProtectedRoute.jsx";

function RouterConfig() {
  return (
    <Routes>
      <Route
        path={PATH.SIGNIN}
        element={<PublicRoute element={<WEB_PAGES.SIGNIN />} />}
      />
      <Route
        path={PATH.SIGNUP}
        element={<PublicRoute element={<WEB_PAGES.SIGNUP />} />}
      />
      <Route
        path={PATH.OTPCODE}
        element={<PublicRoute element={<WEB_PAGES.OTPCODE />} />}
      />
      <Route
        path={PATH.FORGETPASSWORD}
        element={<PublicRoute element={<WEB_PAGES.FORGETPASSWORD />} />}
      />
      <Route
        path={PATH.TERMSANDCONDITION}
        element={<PublicRoute element={<WEB_PAGES.TERMS_AND_CONDITIONS />} />}
      />
      <Route
        path={PATH.PRIVACYPOLICY}
        element={<PublicRoute element={<WEB_PAGES.PRIVACY_POLICY />} />}
      />
      <Route
        path={PATH.DOCTOR_DASHBOARD}
        element={<ProtectedRoute element={<WEB_PAGES.DOCTOR_DASHBOARD />} />}
      />
      <Route
        path={PATH.APPOINTMENT}
        element={<ProtectedRoute element={<WEB_PAGES.APPOINTMENT />} />}
      />
      <Route
        path={PATH.PATIENTS}
        element={<ProtectedRoute element={<WEB_PAGES.PATIENTS />} />}
      />
      <Route
        path={PATH.STAFF}
        element={<ProtectedRoute element={<WEB_PAGES.STAFF />} />}
      />
      <Route
        path={PATH.CHAT}
        element={<ProtectedRoute element={<WEB_PAGES.CHAT />} />}
      />
      <Route
        path={PATH.STAFF_SCREEN_CHAT}
        element={<ProtectedRoute element={<WEB_PAGES.CHAT />} />}
      />
      <Route
        path={PATH.SLOTS}
        element={<ProtectedRoute element={<WEB_PAGES.SLOTS />} />}
      />
      <Route
        path={PATH.TELE_CONSULTATION}
        element={<ProtectedRoute element={<WEB_PAGES.TELE_CONSULTATION />} />}
      />
      <Route
        path={PATH.PATIENTS_DETAILS}
        element={<ProtectedRoute element={<WEB_PAGES.PATIENTDETAILS />} />}
      />

      <Route
        path={PATH.PROFILE}
        element={<ProtectedRoute element={<WEB_PAGES.PROFILE />} />}
      />
      <Route
        path={PATH.APPOINTMENT_NOTES_DETAILS}
        element={
          <ProtectedRoute element={<WEB_PAGES.APPOINTMENTNOTESDETAILS />} />
        }
      />
      {/* <Route
        path={PATH.PROFILE_EDIT}
        element={<ProtectedRoute element={<WEB_PAGES.PROFILEEDIT />} />}
      /> */}
      <Route
        path={PATH.APPOINTMENT_NOTES}
        element={<ProtectedRoute element={<WEB_PAGES.APPOINTMENTNOTES />} />}
      />
      <Route
        path={PATH.STAFF_CHAT}
        element={<ProtectedRoute element={<WEB_PAGES.STAFFCHAT />} />}
      />

      <Route
        path={PATH.PATIENT_PRESCRIPTION}
        element={
          <ProtectedRoute element={<WEB_PAGES.PATIENT_PRESCRIPTION />} />
        }
      />

      {/* Patient Screens */}
      <Route
        path={PATH.PATIENT_DASHBOARD}
        element={<ProtectedRoute element={<WEB_PAGES.PATIENT_DASHBOARD />} />}
      />

      <Route
        path={PATH.PATIENT_PAYMENTDETAILS}
        element={
          <ProtectedRoute element={<WEB_PAGES.PATIENT_PAYMENTDETAILS />} />
        }
      />

      <Route
        path={PATH.PATIENT_APPOINTMENTDETAILS}
        element={
          <ProtectedRoute element={<WEB_PAGES.PATIENT_APPOINTMENTDETAILS />} />
        }
      />
      <Route
        path={PATH.PATIENT_TELE_CONSULTATION}
        element={
          <ProtectedRoute element={<WEB_PAGES.PATIENT_TELE_CONSULTATION />} />
        }
      />
      <Route
        path={PATH.PATIENT_PROFILE}
        element={<ProtectedRoute element={<WEB_PAGES.PATIENT_PROFILE />} />}
      />
      <Route
        path={PATH.DOCTOR_LIST}
        element={<ProtectedRoute element={<WEB_PAGES.DOCTOR_LIST />} />}
      />
      <Route
        path={PATH.SUPERADMIN_USERMANAGEMENT}
        element={
          <ProtectedRoute element={<WEB_PAGES.SUPERADMIN_USERMANAGEMENT />} />
        }
      />
      <Route
        path={PATH.DOCTOR_DETAILS}
        element={<ProtectedRoute element={<WEB_PAGES.DOCTOR_DETAILS />} />}
      />

      <Route
        path={PATH.SUPERADMIN_PAYMENTDETAILS}
        element={
          <ProtectedRoute element={<WEB_PAGES.SUPERADMIN_PAYMENTDETAILS />} />
        }
      />
      <Route
        path={PATH.SUPERADMIN_SETTINGS}
        element={<ProtectedRoute element={<WEB_PAGES.SUPERADMIN_SETTINGS />} />}
      />
      <Route
        path={PATH.PERSONAL_INFORMATION}
        element={
          <ProtectedRoute element={<WEB_PAGES.PERSONAL_INFORMATION />} />
        }
      />
      <Route
        path={PATH.MEDICAL_HISTORY}
        element={<ProtectedRoute element={<WEB_PAGES.MEDICAL_HISTORY />} />}
      />
      <Route
        path={PATH.PHARMACY}
        element={<ProtectedRoute element={<WEB_PAGES.PHARMACY />} />}
      />
      <Route
        path={PATH.ADMIN_DASHBOARD}
        element={<ProtectedRoute element={<WEB_PAGES.ADMIN_DASHBOARD />} />}
      />
      <Route
        path={PATH.SUPERADMIN_DASHBOARD}
        element={
          <ProtectedRoute element={<WEB_PAGES.SUPERADMIN_DASHBOARD />} />
        }
      />
      <Route
        path={PATH.ADMIN_USERMANAGEMENT}
        element={
          <ProtectedRoute element={<WEB_PAGES.ADMIN_USERMANAGEMENT />} />
        }
      />

      <Route
        path={PATH.ADMIN_PAYMENTDETAILS}
        element={
          <ProtectedRoute element={<WEB_PAGES.ADMIN_PAYMENTDETAILS />} />
        }
      />
      <Route
        path={PATH.ADMIN_SETTINGS}
        element={<ProtectedRoute element={<WEB_PAGES.ADMIN_SETTINGS />} />}
      />
      <Route
        path={PATH.COMPLETE_PROFILE}
        element={
          <ProtectedRoute element={<WEB_PAGES.PERSONAL_INFORMATION />} />
        }
      />
      <Route
        path={PATH.ADMIN_DOCTORS}
        element={<ProtectedRoute element={<WEB_PAGES.ADMIN_DOCTORS />} />}
      />
      <Route
        path={PATH.SUPERADMIN_DOCTORS}
        element={<ProtectedRoute element={<WEB_PAGES.SUPERADMIN_DOCTORS />} />}
      />
      <Route
        path={PATH.ADMIN_FEES}
        element={<ProtectedRoute element={<WEB_PAGES.ADMIN_FEES />} />}
      />
      <Route
        path={PATH.SUPERADMIN_FEES}
        element={<ProtectedRoute element={<WEB_PAGES.SUPERADMIN_FEES />} />}
      />
      <Route
        path={PATH.SUPERADMIN_FORMS}
        element={<ProtectedRoute element={<WEB_PAGES.SUPERADMIN_FORMS />} />}
      />
      <Route
        path={PATH.ADMIN_FORMS}
        element={<ProtectedRoute element={<WEB_PAGES.ADMIN_FORMS />} />}
      />
      <Route
      path={PATH.SUPERADMIN_QUESTIONNAIRE}
      element={<ProtectedRoute element={<WEB_PAGES.SUPERADMIN_QUESTIONNAIRE />} />}
    />
    <Route
      path={PATH.ADMIN_QUESTIONNAIRE}
      element={<ProtectedRoute element={<WEB_PAGES.ADMIN_QUESTIONNAIRE />} />}
    />
    <Route
      path={PATH.PATIENT_PRESCRIPTION_FORMS}
      element={<ProtectedRoute element={<WEB_PAGES.PATIENT_PRESCRIPTION_FORMS />} />}
    />
     <Route
      path={PATH.PATIENT_PLANS}
      element={<ProtectedRoute element={<WEB_PAGES.PATIENT_PLANS />} />}
    />
    <Route
      path={PATH.PATIENT_PURCHASE_PLANS}
      element={<ProtectedRoute element={<WEB_PAGES.PATIENT_PURCHASE_PLANS />} />}
    />
    <Route
      path={PATH.DOCTOR_PRESCRIPTION_DASHBOARD}
      element={<ProtectedRoute element={<WEB_PAGES.DOCTOR_PRESCRIPTION_DASHBOARD />} />}
    />
    <Route
      path={PATH.DOCTOR_PRESCRIPTION_FORM}
      element={<ProtectedRoute element={<WEB_PAGES.DOCTOR_PRESCRIPTION_FORM />} />}
    />
    <Route
      path={PATH.SUPERADMIN_PLANS}
      element={<ProtectedRoute element={<WEB_PAGES.SUPERADMIN_PLANS />} />}
    />
    <Route
        path={PATH.DOCTOR_MEDICATION_FORM}
        element={
          <ProtectedRoute element={<WEB_PAGES.DOCTOR_MEDICATION_FORM />} />
        }
      />
    <Route
      path={PATH.ADMIN_PLANS}
      element={<ProtectedRoute element={<WEB_PAGES.ADMIN_PLANS />} />}
    />
      <Route path={PATH.NO_PAGE_FOUND} element={<WEB_PAGES.NO_PAGE_FOUND />} />
    </Routes>
  );
}

export default RouterConfig;
