import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../../../components/GenericActions";
import patientOnlinePrescServices from "./PatientOnlinePrescription.service";

const initialState = {
  patientOnlinePrescForm: null,
  getAllPatientOnlinePrescForm: [],
  patientPrescriptionPayment: null,
  getAllFormCount: null,

  isLoggedIn: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createPatientPresForm = createAsyncThunk(
  "patient/createPresForm",
  async ({ formSubmit, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await patientOnlinePrescServices.createPatientPresForm(
        formSubmit
      );
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPatientPresForm = createAsyncThunk(
  "patient/getPresForm",
  async (finalData, thunkAPI) => {
    try {
      return await patientOnlinePrescServices.getPatientPresForm(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllPatientPresForm = createAsyncThunk(
  "patient/getAllPresForm",
  async (finalData, thunkAPI) => {
    try {
      const response =  await patientOnlinePrescServices.getAllPatientPresForm(finalData);
      return response
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const patientPresPayment = createAsyncThunk(
  "patient/presPayment",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await patientOnlinePrescServices.formOneTimePayment(
        finalData
      );
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllFormCountData = createAsyncThunk(
  "patient/allFormCount",
  async (thunkAPI) => {
    try {
      return await patientOnlinePrescServices.getAllFormCount();
      } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const patientOnlinePrescripionSlice = createSlice({
  name: "patientOnlinePrescripion",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPatientPresForm.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createPatientPresForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientOnlinePrescForm = action.payload;
      })
      .addCase(createPatientPresForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getPatientPresForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPatientPresForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientOnlinePrescForm = action.payload;
      })
      .addCase(getPatientPresForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllPatientPresForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPatientPresForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllPatientOnlinePrescForm = action.payload;
      })
      .addCase(getAllPatientPresForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(patientPresPayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patientPresPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientPrescriptionPayment = action.payload;
      })
      .addCase(patientPresPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllFormCountData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFormCountData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllFormCount = action.payload;
      })
      .addCase(getAllFormCountData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = patientOnlinePrescripionSlice.actions;

export default patientOnlinePrescripionSlice.reducer;
