import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../../../components/GenericActions";
import onlinePrescriptionFormServices from "./OnlinePrescriptionForm.service";

const initialState = {
  adminOnlinePrescripionForm: null,
  getAllAdminOnlinePrescripionForm: [],
  questionStatus: null,
  formStatus: null,
  questionRequired: null,
  patientFormStatus: null,

  isLoggedIn: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createAdminPresForm = createAsyncThunk(
  "admin/createPresForm",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await onlinePrescriptionFormServices.createAdminPresForm(
        finalData
      );
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateAdminPresForm = createAsyncThunk(
  "admin/updatePresForm",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await onlinePrescriptionFormServices.updateAdminPresForm(
        finalData
      );
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAdminPresForm = createAsyncThunk(
  "admin/getAdminPresForm",
  async (finalData, thunkAPI) => {
    try {
      return await onlinePrescriptionFormServices.getAdminPresForm(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllAdminPresForm = createAsyncThunk(
  "admin/getAllPresForm",
  async (finalData, thunkAPI) => {
    try {
      const response = await onlinePrescriptionFormServices.getAllAdminPresForm(
        finalData
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification("Network Error", false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteAdminQuestion = createAsyncThunk(
  "admin/deleteQuestion",
  async ({ finalData, getPresForm }, thunkAPI) => {
    try {
      const response = await onlinePrescriptionFormServices.deleteAdminQuestion(
        finalData
      );
      if (response.succeeded === true) {
        if (getPresForm) {
          getPresForm(response);
        }
        Notification("Question Deleted", response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const adminQuestionStatus = createAsyncThunk(
  "admin/questionStatus",
  async ({finalData, getPresForm}, thunkAPI) => {
    try {
      const response = await onlinePrescriptionFormServices.questionAdminStatus(
        finalData
      );
      if (response.succeeded === true) {
        if (getPresForm) {
          getPresForm(response);
        }
        Notification("Question Status Updated", response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const adminRequiredStatus = createAsyncThunk(
  "admin/questionRequired",
  async ({finalData, getPresForm}, thunkAPI) => {
    try {
      const response = await onlinePrescriptionFormServices.questionAdminRequired(
        finalData
      );
      if (response.succeeded === true) {
        if (getPresForm) {
          getPresForm(response);
        }
        Notification("Question Required Updated", response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const adminFormStatus = createAsyncThunk(
  "admin/formStatus",
  async ({finalData, moveToNext}, thunkAPI) => {
    try {
      const response = await onlinePrescriptionFormServices.formAdminStatus(
        finalData
      );
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
        Notification("Form Status Updated", response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const patientFormStatus = createAsyncThunk(
  "doctor/updateFormStatus",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await onlinePrescriptionFormServices.updatePatientFormStatus(
        finalData
      );
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const adminOnlinePrescripionSlice = createSlice({
  name: "adminOnlinePrescripion",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAdminPresForm.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createAdminPresForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adminOnlinePrescripionForm = action.payload;
      })
      .addCase(createAdminPresForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateAdminPresForm.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updateAdminPresForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adminOnlinePrescripionForm = action.payload;
      })
      .addCase(updateAdminPresForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllAdminPresForm.pending, (state) => {
        state.isLoading = true;
        state.getAllAdminOnlinePrescripionForm = [];
      })
      .addCase(getAllAdminPresForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllAdminOnlinePrescripionForm = action.payload;
      })
      .addCase(getAllAdminPresForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAdminPresForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminPresForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adminOnlinePrescripionForm = action.payload;
      })
      .addCase(getAdminPresForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deleteAdminQuestion.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(deleteAdminQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adminOnlinePrescripionForm = action.payload;
      })
      .addCase(deleteAdminQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(adminQuestionStatus.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(adminQuestionStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.questionStatus = action.payload;
      })
      .addCase(adminQuestionStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(adminRequiredStatus.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(adminRequiredStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.questionRequired = action.payload;
      })
      .addCase(adminRequiredStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(adminFormStatus.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(adminFormStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formStatus = action.payload;
      })
      .addCase(adminFormStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(patientFormStatus.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(patientFormStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientFormStatus = action.payload;
      })
      .addCase(patientFormStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      ;
  },
});
export const { clearData } = adminOnlinePrescripionSlice.actions;

export default adminOnlinePrescripionSlice.reducer;
