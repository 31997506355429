import logo from "./logo.png";
import sidebarlogo from "./sidebar-logo.png";
import userheaderimg from "./header-user-profile.png";
import user from "./user.png";
import admin from "./admin.png";
import notification from "./notification.png";
import avatar from "./avatar.png";
import Upload from "./Upload.png";
import appointmentIcon from "./appointment-icon.svg";
import newPatientIcon from "./new-patient-icon.svg";
import adminUserIcon from "./admin-user.svg";
import patientImg from "./patient-img.png";
import patientImg2 from "./patient-img-2.png";
import patientImg3 from "./patient-img-3.png";
import patientImg4 from "./patient-img-4.png";
import patientImg5 from "./patient-img-5.png";
import newPatientGray from "./new-patient-gray.png";
import teleConsultationPatient from "./tele-consultation-patient.png";
import chatImg1 from "./chat-img-1.png";
import chatImg2 from "./chat-img-2.png";
import chatImg3 from "./chat-img-3.png";
import chatVideo from "./chat_video.png";
import chatCall from "./chat_call.png";
import sendIcon from "./message-send-icon.png";
import micIcon from "./mic-icon.png";
import appointmentDoctor from "./appointment-doctor.png";
import prescriptionIcon from "./prescription-icon.svg";
import referralIcon from "./referral-icon.svg";
import medicalCertificateIcon from "./medical-certificate-icon.svg";
import notesCompletedIcon from "./notes-completed-icons.png";
import availableSlot from "./available-slot.png";
import bookedSlot from "./booked-slot.png";
import Profile1 from "./Profile1.png";
import uploadIcon from "./upload-icon.svg";
import infoIcon from "./info-icon.svg";
import verticalLine from "./vertical-line.png";
import profileUpload from "./profile-upload.png";
import totalDoctorsIcon from "./total-doctors-icon.svg";
import totalStaffIcon from "./total-staff-icon.svg";
import upcomingAppointmentIcon from "./upcoming-appointment-icon.png";
import roleManagementIcon from "./role-management-icon.png";
import patientprofile from "./patientprofile.png"
import accordionArrow from "./accordion-arrow.png";
import delIcon from "./del-icon.png";
import uploadimg from "./upload2.png"
import doctorsPlus from "./doctors-plus.png"
import twilioLogo from "./twilio-logo.png"
import purchasePlans from "./purchase-plans.svg"
import buyPlan from "./buy-plan.svg"
import pdfDoc from "./pdf-doc.png"

const IMAGES = {
  LOGO: logo,
  SIDEBAR_LOGO: sidebarlogo,
  ADMIN: admin,
  USER_HEADER_IMG: userheaderimg,
  BELL_ICON: notification,
  APPOINTMENT_ICON: appointmentIcon,
  NEW_PATIENT_ICON: newPatientIcon,
  ADMIN_USER_ICON: adminUserIcon,
  PATIENT_IMG: patientImg,
  PATIENT_IMG2: patientImg2,
  PATIENT_IMG3: patientImg3,
  PATIENT_IMG4: patientImg4,
  PATIENT_IMG5: patientImg5,
  APPOINTMENT_DOCTOR: appointmentDoctor,
  NEW_PATIENT_GRAY: newPatientGray,
  TELE_CONSULTATION_PATIENT: teleConsultationPatient,
  USER: user,
  AVATAR: avatar,
  UPLOAD_FILE: Upload,
  CHAT_IMG_1: chatImg1,     
  CHAT_IMG_2: chatImg2,
  CHAT_IMG_3: chatImg3,
  CHAT_VIDEO_ICON: chatVideo,
  CHAT_CALL_ICON: chatCall,
  SEND_ICON: sendIcon,
  MIC_ICON: micIcon,
  PRESCRIPTION_ICON: prescriptionIcon,
  REFERRAL_ICON: referralIcon,
  MEDICAL_CERTIFICATE_ICON: medicalCertificateIcon,
  NOTES_COMPLETED_ICON: notesCompletedIcon,
  AVAILABLE_SLOT: availableSlot,
  BOOKED_SLOT: bookedSlot,
  PROFILE1: Profile1,
  UPLOAD_ICON: uploadIcon,
  INFO_ICON: infoIcon,
  VERTICAL_LINE: verticalLine,
  PROFILE_UPLOAD: profileUpload,
  TOTAL_DOCTORS_ICON: totalDoctorsIcon,
  TOTAL_STAFF_ICON: totalStaffIcon,
  UPCOMING_APPOINTMENT_ICON: upcomingAppointmentIcon,
  ROLE_MANAGEMENT_ICON: roleManagementIcon,
  PATIENT_PROFILE: patientprofile,
  ACCORDION_ARROW: accordionArrow,
  DEL_ICON: delIcon,
  uploadimg: uploadimg,
  DOCTORS_PLUS: doctorsPlus,
  TWILIO_LOGO: twilioLogo,
  PURCHASE_PLANS: purchasePlans,
  BUY_PLAN: buyPlan,
  PDF_DOC: pdfDoc,
};
export default IMAGES;
