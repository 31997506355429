import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../../../components/GenericActions";
import patientPrescriptionServices from "./patientPrescription.service";

const initialState = {
  createPatientPrescription: [],
  allPrescription: [],
  patientPresData: null,
  PreMedicineData: null,
  ShowPatientPresData: null,
  deletePres: null,
  MedicationsData: null,
  onlinePresMedicinesList: null,
  confirmOnlinePresData: null,

  isLoggedIn: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createPatientPres = createAsyncThunk(
  "patient/createPrescription",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response =
        await patientPrescriptionServices.createPatientPrescription(finalData);
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPresMedicine = createAsyncThunk(
  "patient/getPescription",
  async ({ finalData }, thunkAPI) => {
    try {
      return await patientPrescriptionServices.getPescription(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPrescriptionList = createAsyncThunk(
  "patient/getPrescriptionList",
  async ({ finalData }, thunkAPI) => {
    try {
      return await patientPrescriptionServices.getAllPrescription(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const medicationList = createAsyncThunk(
  "patient/getMedicationList",
  async (finalData, thunkAPI) => {
    try {
      const response = await patientPrescriptionServices.getMedicationList(
        finalData
      );
      if (response.succeeded === true) {
        return response;
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOnlinePresMedicines = createAsyncThunk(
  "patient/onlinePresMedicinesList",
  async (finalData, thunkAPI) => {
    try {
      const response =
        await patientPrescriptionServices.getOnlinePresMedicinesList(finalData);
      if (response.succeeded === true) {
        return response;
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const confirmOnlinePres = createAsyncThunk(
  "patient/confirmOnlinePres",
  async (finalData, thunkAPI) => {
    try {
      const response = await patientPrescriptionServices.confirmOnlinePres(
        finalData
      );
      if (response.succeeded === true) {
        Notification(response.message, response.succeeded);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const showPatientPres = createAsyncThunk(
  "patient/showPatientPres",
  async ({ finalData }, thunkAPI) => {
    try {
      const response =
        await patientPrescriptionServices.showPatientPrescription(finalData);
      if (response.succeeded === true) {
        // Notification(response.message, response.succeeded);
        return response;
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePatientPres = createAsyncThunk(
  "patient/prescription",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response =
        await patientPrescriptionServices.deletePatientPrescription(finalData);
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess();
        }
        Notification(response.message, response.succeeded);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatePatientPres = createAsyncThunk(
  "patient/updatePrescription",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response =
        await patientPrescriptionServices.updatePatientPrescription(finalData);
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess();
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const patientPrescriptionSlice = createSlice({
  name: "patientPrescription",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPatientPres.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createPatientPres.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createPatientPrescription = action.payload;
      })
      .addCase(createPatientPres.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getPrescriptionList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPrescriptionList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allPrescription = action.payload;
      })
      .addCase(getPrescriptionList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deletePatientPres.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePatientPres.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deletePres = action.payload;
      })
      .addCase(deletePatientPres.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updatePatientPres.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updatePatientPres.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientPresData = action.payload;
      })
      .addCase(updatePatientPres.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getPresMedicine.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPresMedicine.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.PreMedicineData = action.payload.data;
      })
      .addCase(getPresMedicine.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(showPatientPres.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(showPatientPres.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.ShowPatientPresData = action.payload.data;
      })
      .addCase(showPatientPres.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(medicationList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(medicationList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.MedicationsData = action.payload.data;
      })
      .addCase(medicationList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getOnlinePresMedicines.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOnlinePresMedicines.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.onlinePresMedicinesList = action.payload.data;
      })
      .addCase(getOnlinePresMedicines.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(confirmOnlinePres.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(confirmOnlinePres.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.confirmOnlinePresData = action?.payload?.data;
      })
      .addCase(confirmOnlinePres.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = patientPrescriptionSlice.actions;

export default patientPrescriptionSlice.reducer;
